import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import _Home from './components/Home/_Home';
import Home from './components/Home/Home';
import ClusterDemo from './components/Home/ClusterDemo';
import Create from './components/Create/Create';
import Assign from './components/Assign/Assign';
import AnonAssign from './components/AnonAssign/AnonAssign';
import Review from './components/Review/Review';
import Track from './components/Track/Track';
import TrackAnon from './components/TrackAnon/TrackAnon';
import Recycle from './components/Recycle';
import Page404 from './components/Page404';
import ReviewTranscript from './components/Review/ReviewTranscript';
import SignUp from './components/User/SignUp';
import SignUpMenu from './components/User/SignUpMenu';
import Login from './components/User/Login';
import RequestPasswordReset from './components/User/RequestPasswordReset'
import PasswordChange from './components/User/PasswordChange';
import ConfirmStaffSignup from './components/User/ConfirmStaffSignup';
import Manage from './components/Manage/Manage';
import Help from './components/Help/Help';
import Analyze from './components/Analyze/Analyze'
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {setEnv} from './actions/setEnv';
import 'react-widgets/dist/css/react-widgets.css';


///////////////////////////just testing//////////////////////////////

let testing = false;


class Testing extends Component {


  render() {
    return (
      <div>
        testing
      </div>
    )
  }
}

///////////////////////////////////end testing/////////////////////////

class App extends Component {
  componentDidMount() {
    const {setEnv} = this.props;
    setEnv({
      TWILIO_NUMBER: window.TWILIO_NUMBER,
      URLPrefix: `${window.AWS_BASEURL}/${window.AWS_TEST_ITEM_TRANSCRIPTION_BUCKET}/`,
      DEVSTORE: window.DEVSTORE,
      TAKER_HOST: window.TAKER_HOST,
      MAKER_HOST: window.MAKER_HOST,
      AWS_GATEWAY: window.AWS_GATEWAY,
      OPENAI_SECRETKEY: window.OPENAI_SECRETKEY
    })
  }

  render() {
    return (
      testing ? <Testing/> :
        <div>
          <Router>
            <Switch>
              <Route exact path="/" component={_Home} firstRoute={true}/>
              <Route path="/home/" component={Home}/>
              <Route path="/create/" component={Create}/>
              <Route path="/assign/" component={Assign}/>
              <Route path="/anonAssign/" component={AnonAssign}/>
              <Route path="/trackAnon/" component={TrackAnon}/>
              <Route path="/review/" component={Review}/>
              <Route path="/track/" component={Track}/>
              <Route path="/signup/" component={SignUp}/>
              <Route path="/signUpMenu/" component={SignUpMenu}/>
              <Route path="/login/" component={Login}/>
              <Route path="/password-reset/" component={RequestPasswordReset}/>
              <Route path="/recycle/" component={Recycle}/>
              <Route path="/transcript/:TIN/:testName/:showMessage/:ro"
                     component={ReviewTranscript}/>
              <Route path="/cluster/:analysisId" component={ClusterDemo}/>
              <Route path="/pwchange" component={PasswordChange}/>
              <Route path="/staffconfirm" component={ConfirmStaffSignup}/>
              <Route path="/manage/:menuItem" component={Manage}/>
              <Route path="/help/:menuItem" component={Help}/>
              <Route path="/help" component={Help}/>
              <Route path="/analyze/" component={Analyze}/>
              <Route component={Page404}/>
            </Switch>
          </Router>
        </div>

    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setEnv
  }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(App);


module.exports = {

createCSVcontent(testObj){ //this function called from CreateFooter
  let headings = 'testName,Item Number,Prompt Text,Options,AI-Assessment,Grammar,Pronunciation,Sentiment,Keywords' + '\r\n';
  let csvContent = headings;

  for (var i=0; i<testObj.rows.length -1; i++){  //the last row doesn't actually hold real data
    csvContent = csvContent + this.getTestName(testObj) + ',';
    csvContent = csvContent + this.getPromptPosition(testObj.rows[i]) + ',';
    csvContent = csvContent + this.getPromptText(testObj.rows[i]) + ',';
    csvContent = csvContent + this.getOptionsInfo(testObj.rows[i]) + ',';
    csvContent = csvContent + this.getChatGPTinfo(testObj.rows[i].chatGPTPrompt) + ',';
    csvContent = csvContent + this.getGrammarInfo(testObj.rows[i]) + ',';
    csvContent = csvContent + this.getPronunciationInfo(testObj.rows[i]) + ',';
    csvContent = csvContent + this.getSentimentInfo(testObj.rows[i]) + ',';
    csvContent = csvContent + this.getKeywordInfo(testObj.rows[i].scoringParameters) + '\r\n';
    }
  let fileName = testObj.testName + "_" + this.getDateAndTime();
  this.writeCSVfile(csvContent,fileName);
},

escapeCsvValue(value) {
    // Check if the value contains a comma, double quotes, or a newline
  if (value != undefined){
    if (value.includes(",") || value.includes('"') || value.includes("\n")) {
      // Escape double quotes by doubling them
      value = value.replace(/"/g, '""');
      // Wrap the value in double quotes
      return `"${value}"`;
    }
    return value;
  } else return "";
},

getChatGPTinfo(chatObj) {
    if (chatObj.checkbox) {

      let prompt = "prompt: " + chatObj.gptPrompt;
      let advice = "advice: " + chatObj.assistant;
      let persona = "persona: " + chatObj.system;
      let consistency = "consistency: " + chatObj.temperature;
      let succinctness = "succinctness: " + chatObj.top_p;

      let usesJSON ="";
      let functionDescription = "key-value pairs: - NA -";
      let reportTemplate = "emailReport template: - NA -";

      if (chatObj.useFunction) {
        usesJSON = "usesJSON: true"
        functionDescription = "key-value pairs: " + chatObj.functionDescription;
        reportTemplate = "emailReport template: " + chatObj.emailTemplate;
        }
      else usesJSON = "usesJSON: false" ;

      let concatSettings =  usesJSON + "\r\n \r\n" +
                            prompt + "\r\n \r\n" +
                            advice + "\r\n \r\n" +
                            persona + "\r\n \r\n" +
                            consistency + "\r\n \r\n" +
                            succinctness + "\r\n \r\n" +
                            functionDescription + "\r\n \r\n" +
                            reportTemplate;
      return this.escapeCsvValue(concatSettings);
    }
    return "not selected";
},

getOptionsInfo(testObj){
      let itemType = "Item type: question";
      if (!testObj.awaitResponse) {
        itemType = "Item type: instruction";
        itemType = itemType + "\r\n" +
                   "Seconds before advancing: "  + testObj.instructionDelay + " seconds" + "\r\n";
      } else {
        itemType = itemType + "\r\n" +
                  "Silence before advancing: " + testObj.delay + " seconds" + "\r\n" +
                  "Repeat if no response: " + testObj.replayOnSilence + "\r\n";
      }
      let mediaSelected = "Prompts presented via: ";
      if (testObj.audioOnly) {
        mediaSelected = mediaSelected + "audio only" + "\r\n";
      } else if (testObj.mediaOption === "image") {
        mediaSelected = mediaSelected + "audio + image" + "\r\n";
        mediaSelected = mediaSelected + "Image URL: " + testObj.imageURL + "\r\n";
      } else if (testObj.mediaOption === "video" ) {
        mediaSelected = mediaSelected + "video" + "\r\n";
        mediaSelected = mediaSelected + "Video URL: " + testObj.videoURL + "\r\n";
      } else if (testObj.htmlText.length > 5 ) {
        mediaSelected = mediaSelected + "HTML" + "\r\n";
        mediaSelected = mediaSelected + testObj.htmlText + "\r\n";
      }
      let recommendedElements = "Repeat button visible: " + testObj.repeatArrow + "\r\n" +
                                "Next button visible: " + testObj.nextArrow + "\r\n";

      let optionalElements    = "Timer selected: " + testObj.eggTimerEnabled +  "\r\n";
      if (testObj.eggTimerEnabled) {
          optionalElements    = optionalElements +
                                "Timer setting: " + testObj.eggTimer + " seconds" + "\r\n";
      }
      if (testObj.spokenURLEnabled) {
          optionalElements = optionalElements +
                                "Human voiced audio: " + testObj.spokenURL;
      }

      let concatSettings = itemType + "\r\n" +
                           mediaSelected + "\r\n" +
                           recommendedElements + "\r\n" +
                           optionalElements;
      return this.escapeCsvValue(concatSettings);
  },

getKeywordInfo(testObj){
    let corrects = "";
    let wrongs = "";
    if (testObj.checkbox) {
      for (var i = 0; i < testObj.correctAnswers.length; i++){
        corrects = corrects + testObj.correctAnswers[i].phrase + " (" +
                             testObj.correctAnswers[i].pct + "%)" + "\r\n";
      }

      for (var i = 0; i < testObj.wrongAnswers.length; i++){
        wrongs = wrongs + testObj.wrongAnswers[i].phrase + " (" +
                             testObj.wrongAnswers[i].pct + "%)" + "\r\n";
      }

      let concatSettings = "Maximum points: " + testObj.maxPts + "\r\n \r\n" +
                           "Correct Answers (max % awarded):\r\n" + corrects +  "\r\n" +
                           "Wrong Answers (max % deducted):\r\n" + wrongs;

      return this.escapeCsvValue(concatSettings);
    }
    return "not selected";
},

getGrammarInfo(testObj){
    if (testObj.checkGrammar) { return "selected"} else {return "not selected"}
},

getPronunciationInfo(testObj){
    if (testObj.checkPronunciation) { return "selected"} else {return "not selected"}
},

getSentimentInfo(testObj){
    if (testObj.checkSentiment.checkbox) { return "selected"} else {return "not selected"}
},

getPromptText(textObj){
  return this.escapeCsvValue(textObj.promptText)
},

getTestName(textObj){
  return textObj.testName
},

getPromptText(textObj){
  return this.escapeCsvValue(textObj.promptText)
},

getPromptPosition(textObj){
  return textObj.promptPosition
},

getDateAndTime() {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const dateTime = `${month}-${day}-${year}_${hours}:${minutes}`;
  return dateTime
},

writeCSVfile (csvContent, csvTitle){
      const blob = new Blob([csvContent], { type: 'text/csv' }) // Create a Blob from the text content
      const link = document.createElement('a') // Create a temporary link element
      link.download = csvTitle + '.csv'
      link.href = URL.createObjectURL(blob) // Create a URL for the Blob and set it as the href attribute
      document.body.appendChild(link) // Append the link to the document (not visible to the user)
      link.click() // Programmatically click the link to trigger the download
      document.body.removeChild(link) // removethe link and revokethe object URL
      URL.revokeObjectURL(link.href)
  }
}
import {GET_SURVEYS} from '../_CONSTANTS';
import $ from 'jquery'
import _ from 'lodash'

export const getSurveys = (id) => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {
      let url = `/test/getSurveys?accountId=${id}`;
      // let url = '/testerror';
      $.ajax({
        url: url,
        type: "GET",
        success: (result) => {
          let surveys = result.tests;
          surveys = _.sortBy(surveys, ['updatedAt']);
          _.reverse(surveys);
          dispatch({
            type: GET_SURVEYS,
            payload: surveys
          })
          resolve(surveys);
        },
        error: (err) => {
          console.log(err);
          reject(err);
        }
      })
    })
  }
}


import $ from 'jquery';

const hasSession = () => {
  return new Promise((resolve, reject) => {
    let url = '/auth/hassession';
    // let url = '/testerror';
    $.ajax({
      url: url,
      type: "GET",
      data: {},
      success (data) {
        let env = data.env;
        window.TWILIO_NUMBER = env.TWILIO_NUMBER;
        window.URLPrefix = `${env.AWS_BASEURL}/${env.AWS_TEST_ITEM_TRANSCRIPTION_BUCKET}/`;
        window.DEVSTORE = env.DEVSTORE;
        window.AWS_GATEWAY = env.AWS_GATEWAY;
        window.OPENAI_SECRETKEY = env.OPENAI_SECRETKEY


        // window.TAKER_HOST = env.TAKER_HOST;
      
        window.TAKER_HOST = window.location.host === 'tmt-maker.herokuapp.com' ?
          "https://tmt-taker.herokuapp.com" : "https://taker-dev.herokuapp.com";

        window.MAKER_HOST = env.MAKER_HOST;

        if (data.user && data.user.verified) {
          resolve(data.user);
        }
        else {
          resolve(null);
        }
      },
      error (err) {
        console.log('error: ', err);
        reject(err);
      }
    });
  })
}

export default hasSession;